import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Header } from "semantic-ui-react"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Header as="h1" textAlign="center">
      Hi there!
    </Header>
    <p>
      Please{" "}
      <a
        href="https://www.homestaynetwork.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        click here
      </a>{" "}
      or click on the image to go to our AHN USA Site
    </p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <a
        href="https://www.homestaynetwork.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          alt="AHN USA"
          src="https://res.cloudinary.com/mystay-international/image/upload/v1543978254/Mystay%20International/ahn-us-logo-400.jpg"
        />
      </a>
    </div>
  </Layout>
)

export default IndexPage
